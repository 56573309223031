import React, { useEffect, useRef, useState } from "react";
import "./contactUs.scss";

import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import {
  Button,
} from "@mui/material";

import { GetPubliccmsContent, fetIpAddress } from "../../utils/Api/api";
import parser from "html-react-parser";
import useGeoLocation from "../../components/geoLocation/GeoLoaction";
import { createTheme, ThemeProvider } from "@mui/material/styles";
const theme = createTheme({
  typography: {
    fontFamily: "var(--OpenSans-Regular)",
  },
});

const ContactUs = () => {
  const [age, setAge] = React.useState("");
  const loaction = useGeoLocation();
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [htmlString, setHtmlStr] = useState([]);

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };
 
  const fetchIpAddress = async () => {
    try {
      const { data } = await fetIpAddress();
      return data;
    } catch (error) {
      return "null";
    }
  };
  const cmsContent = async () => {
    try {
      const response1 = await GetPubliccmsContent({
        ip_address: await fetchIpAddress(),
        form_lat: await loaction.coordinates.lat,
        form_lon: await loaction.coordinates.lng,
        post_id: "6927",
      });

      setHtmlStr(response1.data?.content?.split("<!--nextpage-->"));
    } catch (error) {
      // toast.error(
      //   `Something went wrong, please try again`,
      //   toastCommonestyle as any
      // );
    }
  };
  // 8064

  useEffect(() => {
    cmsContent();
  
  }, []);
  return (
    <>
      <div className="home-container">
        <div className="home-bg-container contactUs-bg-img">
          <div className="home-bg-container-dev">
            {htmlString[0] && <>{parser(htmlString[0])}</>}
          </div>
        </div>
        <div className="contact-from-main-dev">
          {htmlString[1] && <>{parser(htmlString[1])}</>}
          <div className="contact-from-dev">
            <ThemeProvider theme={theme}>
              <TextField
                required
                style={{ height: "50px", fontSize: "16px" }}
                className="input-field"
                id="outlined-basic"
                label="Name"
                variant="outlined"
              />

              <TextField
                required
                style={{ height: "50px", fontSize: "16px" }}
                className="input-field"
                id="outlined-basic"
                label="Email"
                variant="outlined"
              />
              <TextField
                style={{ height: "50px", fontSize: "16px" }}
                className="input-field"
                id="outlined-basic"
                label="Country"
                variant="outlined"
              />
              <TextField
                style={{ height: "50px", fontSize: "16px" }}
                className="input-field"
                id="outlined-basic"
                label="Phone"
                variant="outlined"
                type="number"
              />

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Subject goes here
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={age}
                  label="Subject goes here"
                  onChange={handleChange}
                ></Select>
              </FormControl>
              <TextField
                label="Notes"
                multiline
                minRows={4}
                maxRows={10}
                value={""}
                // onChange={handleChange}
                variant="outlined"
              />

              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Reply via Email or Phone
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={age}
                  label="Reply via Email or Phone"
                  onChange={handleChange}
                ></Select>
              </FormControl>
              <Button className="btn-ticca-contect" type="submit">
                SEND
              </Button>
            </ThemeProvider>
          </div>
          {htmlString[2] && <>{parser(htmlString[2])}</>}
        </div>
     
      </div>
    </>
  );
};

export default ContactUs;
