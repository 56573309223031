import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PageRoutes } from "../../utils/pageRoutes";
import { jwtDecode } from "jwt-decode";
import { toastCommonestyle } from "../../utils/Toast";
import { toast } from "react-toastify";
const LoginToken = () => {
  const location = useLocation();
  const navigator = useNavigate();
  const Notfy = (er: string) => {
    return toast.warning(er, toastCommonestyle as any);
  };
  interface DecodedToken {
    exp: number; // Token expiration time
    // Add any additional claims you expect in your JWT
  }
  
   const validateToken = (token: string ) => {
    try {
      const decoded = jwtDecode<DecodedToken>(token);
      localStorage.setItem("access_token", JSON.stringify(token));
      // Check if the token is expired
  
      setTimeout(()=>{
        if (decoded.exp < Date.now() / 1000) {
          window.location.href =
          "https://dev.login.ticca.com/?domain=dev.www.ticca.ltd";
        }
  
        navigator("/");
      },2000)

      navigator("/");
    } catch (error) {
      window.location.href =
      "https://dev.login.ticca.com/?domain=dev.www.ticca.ltd";
    }
  };
  useEffect(() => {
    const jwtToken = new URLSearchParams(location.search).get("jwt");
   
    const queryParams = new URLSearchParams(location.search);

    const error = queryParams.get("error");
    if (jwtToken) {
      validateToken(jwtToken)
    } else if (error == "unauthorized") {
      Notfy("Your current permission do not allow to access this domain");
      navigator("/");
    } else {
      window.location.href =
        "https://dev.login.ticca.com/?domain=dev.www.ticca.ltd";
    }
  }, [location.search]);

  return <></>;
};

export default LoginToken;
