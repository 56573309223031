import { Button } from "@mui/material";
import "./popMenu.scss";
const PpmPop = ({ setppmPop }: any) => {
  return (
    <>
      <div className="business-summary-popUp">
        <div className="business-summary-main-dev ppm-dev">
          <samp>
            <svg
              width="110"
              height="110"
              viewBox="0 0 110 110"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_4620_67500)">
                <path
                  d="M55 110C85.3757 110 110 85.3757 110 55C110 24.6243 85.3757 0 55 0C24.6243 0 0 24.6243 0 55C0 85.3757 24.6243 110 55 110Z"
                  fill="url(#paint0_linear_4620_67500)"
                />
                <path d="M100 13L13 42.7472L39.5014 48L100 13Z" fill="white" />
                <path
                  d="M13 42.8377L39.4472 48L57 38L13 42.8377Z"
                  fill="#063490"
                />
                <path
                  d="M40 47.9784L44.0712 79L100 13L40 47.9784Z"
                  fill="#ECF0F1"
                />
                <path
                  d="M40.0003 48.1217L44.0624 79L69 49.638L64.6956 42.6302L56.1664 49.7433L57.4024 38H57.4018L40 48.1223H40.0006L40.0003 48.1217Z"
                  fill="#063490"
                />
                <path
                  d="M100 13L49.3736 55.2636L44 79L100 13Z"
                  fill="#16429B"
                />
                <path
                  d="M56.1349 50.0405L49.3673 55.6134L44 79L69 49.9366L64.6848 43L56.1343 50.0408V50.0402L56.1349 50.0405Z"
                  fill="#1959DB"
                />
                <path
                  d="M82.6514 72L100 13L49 55.3294L82.6514 72Z"
                  fill="white"
                />
                <path
                  d="M55.8959 50.0371L49 55.6076L83 72L64.6087 43L55.8968 50.0377V50.0371H55.8959Z"
                  fill="#063490"
                />
                <path
                  d="M-5 88.1197L31.4035 54L32.8996 56.0365L-5 88.1197ZM35.6272 112L68.7826 75.5363L71 76.5537L35.6272 112ZM9.06873 108.422L38.2137 80.4905L39.8001 81.8779L9.06785 108.422H9.06844L9.06873 108.422Z"
                  fill="#ECF0F1"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_4620_67500"
                  x1="55"
                  y1="0"
                  x2="55"
                  y2="110"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#57E4FF" />
                  <stop offset="1" stop-color="#0071ED" />
                </linearGradient>
                <clipPath id="clip0_4620_67500">
                  <rect width="110" height="110" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </samp>
          <p style={{ color: "white" }}>
            Thank you for your request.
            <br />
            Our Founder will review and contact you as soon as possible.
          </p>

          <samp
            onClick={() => {
              setppmPop(false);
            }}
            className="investor-close"
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="rgba(241, 246, 255, 1)"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M23 23L9 9M23 9L9 23"
                stroke="rgba(241, 246, 255, 1)"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </samp>
        </div>
      </div>
    </>
  );
};

export default PpmPop;
