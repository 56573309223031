import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { PageRoutes } from "./utils/pageRoutes";
import "./variables.scss";
import "./styles/styles.scss";
import "react-toastify/dist/ReactToastify.css";

import { ToastContainer } from "react-toastify";
import InvestorCenter from "./pages/InvestorCentrer/InvestorCenter";
import Prospectus from "./pages/PPMContent/Prospectus";
import ExecutiveSummary from "./pages/PPMContent/ExecutiveSummary";
import LoginToken from "./components/VerifyPage/loginToken";
import PageRoute from "./PageRoute";
import Why from "./pages/why/why";
import Contact from "./pages/Contact/ContactUs";
import When from "./pages/when/When";
import Intro from "./pages/PPMContent/Intro";
const Home = lazy(() => import("./pages/home/Home"));
const AboutUs = lazy(() => import("./pages/AboutUs/AboutUs"));
const WorkFromAnyWhere = lazy(
  () => import("./pages/WorkFrom/WorkFromAnyWhere")
);
const TiccaProduct = lazy(() => import("./pages/TICCAProduct/TiccaProduct"));
const Innovation = lazy(() => import("./pages/Innovation/Innovation"));
const TableContents = lazy(() => import("./pages/PPMContent/TableContent"));
function App() {
  //  localStorage.setItem("access_token",JSON.stringify("eyJhbGciOiJIUzI1NiIsImtpZCI6Im90Yy1rZXkiLCJ0eXAiOiJKV1QifQ.eyJhdWQiOlsiZGV2Lnd3dy50aWNjYS5sdGQiXSwiZGlkIjoiNDY1MjdjMzBmMzZhMzI2ZTM3Mjg4NjA1ZWNjYTkxMzQiLCJleHAiOjE3MjEzNTQyNzYsImlhdCI6MTcyMDE0NDY3NiwiaXNzIjoiZGV2LmF1dGgudGljY2EuY29tIiwianRpIjoiVXdiVFAvZ2YwZ3pPVGE2YjArbWpyTjUwcVowUGV2YkRBU3krQkE4ZWtjQT0iLCJzdWIiOiJ1cUI3MDlDQndJWjlINVN3OUkycWE0eDBvVEZGdWI5UHNyRjNwWEt1bGJDVDYxNVFhOWUyZ3dOZSJ9.sJPiIs8LyUsNaJCRZWX0iVJzOxbSU27wufUhLAcRvys"))
  return (
    <Suspense fallback={<div></div>}>
      <ToastContainer />
      <Routes>
        <Route
          path={PageRoutes.home}
          element={
            <PageRoute>
              <Home />
            </PageRoute>
          }
        />
        <Route
          path={PageRoutes.aboutUs}
          element={
            <PageRoute>
              <AboutUs />
            </PageRoute>
          }
        />
        <Route
          path={PageRoutes.WorkFromAnywhere}
          element={
            <PageRoute>
              <WorkFromAnyWhere />
            </PageRoute>
          }
        />
        <Route
          path={PageRoutes.ticcaProduct}
          element={
            <PageRoute>
              <TiccaProduct />
            </PageRoute>
          }
        />
        <Route
          path={PageRoutes.innovation}
          element={
            <PageRoute>
              <Innovation />
            </PageRoute>
          }
        />
        <Route
          path={PageRoutes.InvestorCenter}
          element={
            <PageRoute>
              <InvestorCenter />
            </PageRoute>
          }
        />
        <Route
          path={PageRoutes.TableContent}
          element={
            <PageRoute>
              <TableContents />
            </PageRoute>
          }
        />
        <Route path={PageRoutes.Prospectus} element={<Prospectus />} />
     
        <Route
          path={PageRoutes.ExecutiveSummary}
          element={
            <PageRoute>
              <ExecutiveSummary />
            </PageRoute>
          }
        />
         <Route
          path={PageRoutes.Intro}
          element={
            <PageRoute>
              <Intro />
            </PageRoute>
          }
        />
  
        <Route path={PageRoutes.LoginToken} element={<LoginToken />} />
        <Route
          path={PageRoutes.WhyChooseUs}
          element={
            <PageRoute>
              <Why />
            </PageRoute>
          }
        />
        <Route
          path={PageRoutes.ContactUs}
          element={
            <PageRoute>
              <Contact />
            </PageRoute>
          }
        />
          <Route
          path={PageRoutes.When}
          element={
            <PageRoute>
              <When/>
            </PageRoute>
          }
        />
      </Routes>
    </Suspense>
  );
}

export default App;
