
import { Button } from "@mui/material";
import "./popMenu.scss"
const IntroPop = ({ setInvestorPop}:any)=>{
return (
    <>
     <div className="business-summary-popUp">
            <div className="business-summary-main-dev">
              <samp>
                <svg
                  width="111"
                  height="111"
                  viewBox="0 0 111 111"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M105.104 108.778H5.89691C3.8675 108.778 2.22266 107.133 2.22266 105.104V5.89691C2.22266 3.8675 3.8675 2.22266 5.89691 2.22266H105.104C107.133 2.22266 108.778 3.8675 108.778 5.89691V105.104C108.778 107.133 107.133 108.778 105.104 108.778Z"
                    fill="#D9D9D9"
                  />
                  <path
                    d="M108.778 5.89691C108.778 3.86771 107.133 2.22266 105.104 2.22266H5.89691C3.8675 2.22266 2.22266 3.86793 2.22266 5.89691V20.5942H108.778L108.778 5.89691Z"
                    fill="black"
                  />
                  <path
                    d="M7.73404 105.104V5.89691C7.73404 3.86771 9.37889 2.22266 11.4083 2.22266H5.89691C3.8675 2.22266 2.22266 3.86793 2.22266 5.89691V105.104C2.22266 107.133 3.8675 108.778 5.89691 108.778H11.4083C9.3791 108.778 7.73404 107.133 7.73404 105.104Z"
                    fill="#C4C3C1"
                  />
                  <path
                    d="M11.4083 2.22266H5.89691C3.8675 2.22266 2.22266 3.86793 2.22266 5.89691V20.5942H7.73404V5.89691C7.73404 3.86771 9.3791 2.22266 11.4083 2.22266Z"
                    fill="#55606E"
                  />
                  <path
                    d="M83.0569 77.5469H29.7793V84.8956H83.0569V77.5469Z"
                    fill="white"
                  />
                  <path
                    d="M83.0569 92.2441H29.7793V99.5929H83.0569V92.2441Z"
                    fill="white"
                  />
                  <path
                    d="M70.1972 70.1968H40.8027V48.1508C40.8027 46.1214 42.4476 44.4766 44.477 44.4766H66.523C68.5524 44.4766 70.1972 46.1214 70.1972 48.1508V70.1968Z"
                    fill="#509FE8"
                  />
                  <path
                    d="M49.9884 44.4766H44.477C42.4476 44.4766 40.8027 46.1218 40.8027 48.1508V70.1968H46.3141V48.1508C46.3139 46.1218 47.959 44.4766 49.9884 44.4766Z"
                    fill="#9FCDFF"
                  />
                  <path
                    d="M55.5004 59.1747C57.5297 59.1747 59.1747 57.5297 59.1747 55.5004C59.1747 53.4712 57.5297 51.8262 55.5004 51.8262C53.4712 51.8262 51.8262 53.4712 51.8262 55.5004C51.8262 57.5297 53.4712 59.1747 55.5004 59.1747Z"
                    fill="white"
                  />
                  <path
                    d="M83.0569 81.2207H29.7793V84.895H83.0569V81.2207Z"
                    fill="white"
                  />
                  <path
                    d="M83.0569 95.918H29.7793V99.5922H83.0569V95.918Z"
                    fill="white"
                  />
                  <path
                    d="M16.8016 13.8903C18.2313 13.8903 19.3903 12.7313 19.3903 11.3016C19.3903 9.87189 18.2313 8.71289 16.8016 8.71289C15.3719 8.71289 14.2129 9.87189 14.2129 11.3016C14.2129 12.7313 15.3719 13.8903 16.8016 13.8903Z"
                    fill="#FFC44F"
                  />
                  <path
                    d="M27.1571 13.8903C28.5868 13.8903 29.7458 12.7313 29.7458 11.3016C29.7458 9.87189 28.5868 8.71289 27.1571 8.71289C25.7274 8.71289 24.5684 9.87189 24.5684 11.3016C24.5684 12.7313 25.7274 13.8903 27.1571 13.8903Z"
                    fill="#C4DF64"
                  />
                  <path
                    d="M37.5125 13.8903C38.9422 13.8903 40.1012 12.7313 40.1012 11.3016C40.1012 9.87189 38.9422 8.71289 37.5125 8.71289C36.0828 8.71289 34.9238 9.87189 34.9238 11.3016C34.9238 12.7313 36.0828 13.8903 37.5125 13.8903Z"
                    fill="#FF8C78"
                  />
                  <path
                    d="M13 11.3022C13 13.3988 14.7056 15.1045 16.8022 15.1045C18.8988 15.1045 20.6045 13.3988 20.6045 11.3022C20.6045 9.20562 18.8987 7.5 16.8022 7.5C14.7058 7.5 13 9.20577 13 11.3022ZM18.1774 11.3022C18.1774 12.0606 17.5604 12.6776 16.8021 12.6776C16.0437 12.6776 15.4267 12.0606 15.4267 11.3022C15.4267 10.5439 16.0437 9.92689 16.8021 9.92689C17.5604 9.92689 18.1774 10.544 18.1774 11.3022Z"
                    fill="#FFC44F"
                  />
                  <path
                    d="M23.3555 11.3022C23.3555 13.3988 25.0611 15.1045 27.1577 15.1045C29.2543 15.1045 30.9599 13.3988 30.9599 11.3022C30.9599 9.20562 29.2543 7.5 27.1577 7.5C25.0611 7.5 23.3555 9.20577 23.3555 11.3022ZM28.533 11.3022C28.533 12.0606 27.9161 12.6776 27.1577 12.6776C26.3993 12.6776 25.7824 12.0606 25.7824 11.3022C25.7824 10.5439 26.3993 9.92689 27.1577 9.92689C27.9161 9.92705 28.533 10.544 28.533 11.3022Z"
                    fill="#C4DF64"
                  />
                  <path
                    d="M33.7109 11.3022C33.7109 13.3988 35.4166 15.1045 37.5132 15.1045C39.6098 15.1045 41.3154 13.3988 41.3154 11.3022C41.3154 9.20562 39.6098 7.5 37.5132 7.5C35.4166 7.5 33.7109 9.20577 33.7109 11.3022ZM38.8885 11.3022C38.8885 12.0606 38.2715 12.6776 37.5132 12.6776C36.7548 12.6776 36.1378 12.0606 36.1378 11.3022C36.1378 10.5439 36.7548 9.92689 37.5132 9.92689C38.2715 9.92689 38.8885 10.544 38.8885 11.3022Z"
                    fill="#FF8C78"
                  />
                  <path
                    d="M28.0566 77.5466V84.8954C28.0566 85.8467 28.8277 86.6178 29.779 86.6178H83.0566C84.008 86.6178 84.779 85.8469 84.779 84.8954V77.5466C84.779 76.5953 84.0082 75.8242 83.0566 75.8242H29.779C28.8279 75.8242 28.0566 76.5951 28.0566 77.5466ZM31.5014 79.2688H81.3345V83.1727H31.5014V79.2688Z"
                    fill="#272420"
                  />
                  <path
                    d="M28.0566 99.5926C28.0566 100.544 28.8277 101.315 29.779 101.315H83.0566C84.008 101.315 84.779 100.544 84.779 99.5926V92.2439C84.779 91.2926 84.0082 90.5215 83.0566 90.5215H29.779C28.8279 90.5215 28.0566 91.2923 28.0566 92.2439V99.5926ZM31.5014 93.9663H81.3345V97.8702H31.5014V93.9663Z"
                    fill="#272420"
                  />
                  <path
                    d="M99.592 13.1304C100.543 13.1304 101.314 12.3595 101.314 11.4079C101.314 10.4564 100.544 9.68555 99.592 9.68555H95.9177C94.9664 9.68555 94.1953 10.4564 94.1953 11.4079C94.1953 12.3595 94.9662 13.1304 95.9177 13.1304H99.592Z"
                    fill="black"
                  />
                  <path
                    d="M61.127 11.4079C61.127 12.3593 61.8978 13.1304 62.8494 13.1304H90.4067C91.358 13.1304 92.1291 12.3595 92.1291 11.4079C92.1291 10.4564 91.3583 9.68555 90.4067 9.68555H62.8494C61.8978 9.68576 61.127 10.4566 61.127 11.4079Z"
                    fill="#444242"
                  />
                  <path
                    d="M44.4767 42.7539C41.5009 42.7539 39.0801 45.1747 39.0801 48.1505V70.1965C39.0801 71.1479 39.8512 71.9189 40.8025 71.9189H70.197C71.1483 71.9189 71.9194 71.1481 71.9194 70.1965V48.1505C71.9194 45.1747 69.4985 42.7539 66.5227 42.7539H66.408V37.1276C66.408 32.1258 62.3386 28.0566 57.3371 28.0566H53.6628C48.661 28.0566 44.5919 32.126 44.5919 37.1276V38.9647C44.5919 39.916 45.363 40.6871 46.3143 40.6871C47.2656 40.6871 48.0367 39.9162 48.0367 38.9647V37.1276C48.0367 34.025 50.5607 31.5012 53.663 31.5012H57.3373C60.4398 31.5012 62.9636 34.025 62.9636 37.1276V42.7539H44.4767ZM68.4746 48.1505V68.4741H42.5249V48.1505C42.5249 47.0742 43.4006 46.1985 44.477 46.1985H66.5229C67.5991 46.1987 68.4746 47.0744 68.4746 48.1505Z"
                    fill="#509FE8"
                  />
                  <path
                    d="M55.5002 50.1035C52.5244 50.1035 50.1035 52.5244 50.1035 55.5002C50.1035 57.8738 51.6444 59.8929 53.7778 60.6135V64.686C53.7778 65.6374 54.5488 66.4084 55.5002 66.4084C56.4515 66.4084 57.2226 65.6376 57.2226 64.686V60.6135C59.356 59.8929 60.8968 57.8738 60.8968 55.5002C60.8968 52.5244 58.476 50.1035 55.5002 50.1035ZM55.5002 57.4522C54.4238 57.4522 53.5481 56.5765 53.5481 55.5002C53.5481 54.4238 54.4238 53.5481 55.5002 53.5481C56.5765 53.5481 57.4522 54.4238 57.4522 55.5002C57.4522 56.5765 56.5765 57.4522 55.5002 57.4522Z"
                    fill="#272420"
                  />
                </svg>
              </samp>
              <p>
                Register to read our <samp>Intro</samp>
              </p>
              <Button
                onClick={() => {
                  let Api_URL: any = "";
                  const env = process.env.REACT_APP_STAGE_ENV;
                  switch (env) {
                    case "dev":
                      Api_URL = process.env.REACT_APP_TICCA_DEV_SINUP;
                      break;
                    case "stage":
                      Api_URL = process.env.REACT_APP_TICCA_STAGE_SINUP;
                      break;
                    case "prod":
                      Api_URL = process.env.REACT_APP_TICCA_PRO_SINUP;
                      break;
                    default:
                      Api_URL = process.env.REACT_APP_TICCA_DEV_SINUP;
                      break;
                  }

                  window.location.href = Api_URL;
                }}
                className="btn-ticca"
                type="submit"
              >
                Register
              </Button>
              <samp
                onClick={() => {
                  setInvestorPop(false);
                }}
                className="investor-close"
              >
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M23 23L9 9M23 9L9 23"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </samp>
            </div>
          </div>
    </>
)
}

export default IntroPop