import React, { useState, useEffect } from "react";

interface Location {
  loaded: boolean;
  coordinates: {
    lat: string;
    lng: string;
  };
  error?: {
    code: number;
    message: string;
  };
}

const useGeoLocation = (): Location => {
  const [location, setLocation] = useState<Location>({
    loaded: false,
    coordinates : {
      lat: "0.0",
      lng: "0.0"
    }
  
  });

  const onSuccess = (location: GeolocationPosition) => {
    console.log(location.coords.latitude.toString(),location.coords.longitude.toString())

    setLocation({
      loaded: true,
      coordinates: {
        lat: location.coords.latitude.toString(),
        lng: location.coords.longitude.toString(),
      },
    });
  };

  const onError = (error: GeolocationPositionError) => {
    setLocation({
      loaded: true,
      coordinates : {
        lat: "0.0",
        lng: "0.0"
      },
      error: {
        code: error.code,
        message: error.message,
      },
    });
  };

  // useEffect(() => {
  //   // Check for geolocation support
  //   if (!("geolocation" in navigator)) {
  //     onError({
  //       code: 1,
  //       message: "Geolocation not supported",
  //     } as GeolocationPositionError);
  //     return;
  //   }

  //   // Request geolocation permission
  //   navigator.permissions.query({ name: "geolocation" })
  //     .then((permissionStatus) => {
  //       if (permissionStatus.state === "granted") {
  //         // Permission is granted, get the current position
  //         navigator.geolocation.getCurrentPosition(onSuccess, onError);
  //       } else if (permissionStatus.state === "prompt") {
  //         onError({
  //           code: 2, // Custom code for "permission prompt"
  //           message: "Geolocation permission requested",
  //         } as GeolocationPositionError);
  //       } else {
  //         onError({
  //           code: 3, // Custom code for "permission denied"
  //           message: "Geolocation permission denied",
  //         } as GeolocationPositionError);
  //       }
  //     })
  //     .catch((error) => {
  //       onError({
  //         code: 1,
  //         message: "Error requesting geolocation permission",
  //       } as GeolocationPositionError);
  //     });
  // }, []);
  function handleLocationClick() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(onSuccess, onError);
    } else {
      console.log("Geolocation not supported");
    }
  }

useEffect(()=>{
  handleLocationClick()
},[])

  return  location;
};

export default useGeoLocation;
