import React, { useEffect, useState } from "react";
import "./PPMContent.scss";
// import Ticcalogo from "../../assets/img/Ticcalogo.svg";

import parser from "html-react-parser";
import axios from "axios";
import { GetcmsContent, PPM, fetIpAddress } from "../../utils/Api/api";
import useGeoLocation from "../../components/geoLocation/GeoLoaction";
export interface htmlContent {
  data: string;
}
const Prospectus = () => {
  const loaction = useGeoLocation();
  const [htmlStr, setHtmlStr] = useState<htmlContent[]>([]);

    useEffect(() => {
    const fetchData = async (data: PPM) => {
      try {
        const response1 = await GetcmsContent(data);
        setHtmlStr([{ data: response1.data?.content?.rendered }]);
      
      } catch (error) {
        console.error("Error fetching data from endpoint1:", error);
      }
    };

    const fetchIpAddress = async () => {
      try {
        const { data } = await fetIpAddress();

        fetchData({
          post_id: "2606",
          ip_address: data,
          form_lat: await loaction.coordinates.lat,
          form_lon: await loaction.coordinates.lng,
        });
      } catch (error) {}
    };
    fetchIpAddress();
  
  }, []);

  return (
    <>
      <div className="prospectus-main-dev">
        <div className="prospectus-dev-layer"></div>
  
        <div className="prospectus-infromation-dev">
          <div className="prospectus-infromation-logoDev">
            <div className="prospectus_logo">
              {/* <img src={Ticcalogo} alt="Ticcalogo" /> */}
              <p>Prospectus</p>
            </div>
          </div>
          <div className="prospectus_dp">
            {htmlStr?.length &&
              htmlStr?.map((elem: htmlContent, index) => {
                return <>{parser(elem?.data)}</>         })}
          </div>
        </div>
      </div>
    </>
  );
};
export default Prospectus;

