import React, { useContext, useEffect, useState } from "react";
import "../home/Home.scss";
import { PageRoutes } from "../../utils/pageRoutes";

import "./investorCenter.scss";
import {   Accordion,
  AccordionDetails,
  AccordionSummary,Button } from "@mui/material";

import { useNavigate } from "react-router-dom";
import {
  GetPubliccmsContent,
  LoginTokenVerifty,
  fetIpAddress,
} from "../../utils/Api/api";
import parser from "html-react-parser";
import { Link, Element } from "react-scroll";
import useGeoLocation from "../../components/geoLocation/GeoLoaction";
import { Context } from "../../contextApi/contextProvider";
import InvestorPop from "../../utils/popMenu/IntrorPop";
import PpmPop from "../../utils/popMenu/PpmPop";
const InvestorCenter = () => {
  const navigate = useNavigate();
  const loaction = useGeoLocation();
  const { userLogin, setUserLogin, userShowPPM, setUserShowPPM } =
    useContext(Context);
  const [htmlString, setHtmlStr] = useState([]);
  const [investorPop, setInvestorPop] = useState(false);
  const [ppmPop, setppmPop] = useState(false);
  const [ip_address, setIpaddress] = useState("");
  const [htmlStringFAQ, setHtmlStrFAQ] = useState([]);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const verftyLgoinUser = async () => {
    const token = JSON.parse(localStorage.getItem("access_token") as any);
    if (token) {
      try {
        const { data } = await LoginTokenVerifty({ access_token: token });
        if (data?.status == 200) {
          setUserLogin(true);
        }
      } catch (error) {
        setUserLogin(false);
      }
    }
  };
  const fetchIpAddress = async () => {
    try {
      const { data } = await fetIpAddress();
      return data;
    } catch (error) {
      return "null";
    }
  };
  const handleChangeAccodion =
  (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  const cmsContentFAQ = async () => {
    try {
      const response1 = await GetPubliccmsContent({
        ip_address: await fetchIpAddress(),
        form_lat: await loaction.coordinates.lat,
        form_lon: await loaction.coordinates.lng,
        post_id: "8064",
      });

      setHtmlStrFAQ(response1.data?.content?.split("<!--nextpage-->"));
    } catch (error) {
      // toast.error(
      //   `Something went wrong, please try again`,
      //   toastCommonestyle as any
      // );
    }
  };
  const cmsContent = async () => {
    try {
      const response1 = await GetPubliccmsContent({
        ip_address: await fetchIpAddress(),
        form_lat: await loaction.coordinates.lat,
        form_lon: await loaction.coordinates.lng,
        post_id: "6908",
      });

      setHtmlStr(response1.data?.content?.split("<!--nextpage-->"));
    } catch (error) {
      // toast.error(
      //   `Something went wrong, please try again`,
      //   toastCommonestyle as any
      // );
    }
  };

  useEffect(() => {
    verftyLgoinUser();
    cmsContent();
    cmsContentFAQ()
    document.title = "TICCA | INVESTOR relations";
  }, []);
  return (
    <>
      <div className="home-container">
        {investorPop && (
         <InvestorPop setInvestorPop={setInvestorPop} />
        )}
        {ppmPop && (
         <PpmPop  setppmPop={setppmPop} />
        )}
        <div className={`home-bg-container investor-center-img`}>
          <div
            style={{ flexDirection: "column", gap: "20px", maxWidth: "864px" }}
            className="home-bg-container-dev"
          >
            {htmlString[0] && <>{parser(htmlString[0])}</>}
            {!userLogin && (
              <Button
                onClick={() => {
                  let Api_URL: any = "";
                  const env = process.env.REACT_APP_STAGE_ENV;
                  switch (env) {
                    case "dev":
                      Api_URL = process.env.REACT_APP_TICCA_DEV_SINUP;
                      break;
                    case "stage":
                      Api_URL = process.env.REACT_APP_TICCA_STAGE_SINUP;
                      break;
                    case "prod":
                      Api_URL = process.env.REACT_APP_TICCA_PRO_SINUP;
                      break;
                    default:
                      Api_URL = process.env.REACT_APP_TICCA_DEV_SINUP;
                      break;
                  }

                  window.location.href = Api_URL;
                }}
                className="btn-ticca"
                 type="submit"
              >
                Register
              </Button>
            )}
          </div>
        </div>

        <div className="investor-guided-main-dev">
          <div className="investor-guided-dev">
            {htmlString[1] && <>{parser(htmlString[1])}</>}
          </div>
        </div>
        <div className="investor-guided-main-dev">
          <div className="investor-guided-dev">
            <p className="investor-guided-title">
              Our Vision Mission & Passion
            </p>
            <div className="investor-mision-vision-grid-dev">
              {htmlString[2] && <>{parser(htmlString[2])}</>}
            </div>
          </div>
        </div>

        <div className="Announcement-main-dev">
          <div className="Announcement-dev">
            <div className="Announcement-dev-flex">
              <div className="Announcement-details">
                {htmlString[3] && <>{parser(htmlString[3])}</>}
                <div>
                  { htmlString[4] && <>{parser(htmlString[4])}</>}
                  <br />
                  {userLogin ? (
                    <Button
                     type="submit"
                      onClick={() => {navigate(PageRoutes.Intro)}}
                      className="btn-ticca rb-summary"
                
                    >
                   Read Intro 
                    </Button>
                  ) : (
                    <Button
                     type="submit"
                      onClick={() => {setInvestorPop(true)}}
                      className="btn-ticca rb-summary"
                      style={{ width: "300px" }}
                   
         
                    >
                     Read Intro {" "}
                      <span style={{ margin: "8px 10px 0px 10px" }}>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.1673 7.49935V5.83268C14.1673 3.49935 12.334 1.66602 10.0007 1.66602C7.66732 1.66602 5.83398 3.49935 5.83398 5.83268V7.49935C4.41732 7.49935 3.33398 8.58268 3.33398 9.99935V15.8327C3.33398 17.2493 4.41732 18.3327 5.83398 18.3327H14.1673C15.584 18.3327 16.6673 17.2493 16.6673 15.8327V9.99935C16.6673 8.58268 15.584 7.49935 14.1673 7.49935ZM7.50065 5.83268C7.50065 4.41602 8.58398 3.33268 10.0007 3.33268C11.4173 3.33268 12.5007 4.41602 12.5007 5.83268V7.49935H7.50065V5.83268ZM10.834 14.166C10.834 14.666 10.5007 14.9993 10.0007 14.9993C9.50065 14.9993 9.16732 14.666 9.16732 14.166V11.666C9.16732 11.166 9.50065 10.8327 10.0007 10.8327C10.5007 10.8327 10.834 11.166 10.834 11.666V14.166Z"
                            fill="white"
                          />
                        </svg>
                      </span>
                    </Button>
                  )}
                </div>
              </div>
              <div style={{ position: "relative" }}>
                <img src={"https://public.ticca.com/images/png/InvestorIntroduction.png"} className="Announcement-img-ab " />
               
              </div>
            </div>
          </div>
        </div>
          <Element name="test1" className="element">
        {userLogin &&<div className="contact-faqs-main-dev">
          <p className="contact-faqs-title">FAQs</p>
          <div className="contect-faqs-accordionSummary-main-dev">
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChangeAccodion("panel1")}
              style={{
                boxShadow: "none",
                backgroundColor: "rgba(183, 210, 255, 1)",
              }}
            >
              <AccordionSummary
                style={{
                  backgroundColor: `${
                    expanded === "panel1"
                      ? " rgba(183, 210, 255, 1)"
                      : "rgba(183, 210, 255, 1)"
                  }`,
                 
                  borderRadius: "9px",
                }}
                expandIcon={<img src={"https://public.ticca.com/images/svg/ContactUsArrow.svg"} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
               {htmlStringFAQ[0] && <>{parser(htmlStringFAQ[0])}</>}
              </AccordionSummary>
              <AccordionDetails
                style={{
                  background: "rgba(183, 210, 255, 1)",
                  boxShadow: "none",
                  borderTop:"1px solid rgba(130, 177, 255, 1)",
                }}
              >
                  {htmlStringFAQ[1] && <>{parser(htmlStringFAQ[1])}</>}
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChangeAccodion("panel2")}
              style={{
                boxShadow: "none",
                backgroundColor: "rgba(183, 210, 255, 1)",
              }}
            >
              <AccordionSummary
                style={{
                  backgroundColor: `${
                    expanded === "panel2"
                      ? " rgba(183, 210, 255, 1)"
                      : "rgba(183, 210, 255, 1)"
                  }`,
                  boxShadow: "none",
                  borderRadius: "9px",
                }}
                expandIcon={<img src={"https://public.ticca.com/images/svg/ContactUsArrow.svg"} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
               {htmlStringFAQ[2] && <>{parser(htmlStringFAQ[2])}</>}
              </AccordionSummary>
              <AccordionDetails
                style={{
                  background: "rgba(183, 210, 255, 1)",
                  boxShadow: "none",
                  borderTop:"1px solid rgba(130, 177, 255, 1)",
                }}
              >
                 {htmlStringFAQ[3] && <>{parser(htmlStringFAQ[3])}</>}
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChangeAccodion("panel3")}
              style={{
                boxShadow: "none",
                backgroundColor: "rgba(183, 210, 255, 1)",
              }}
            >
              <AccordionSummary
                style={{
                  backgroundColor: `${
                    expanded === "panel3"
                      ? " rgba(183, 210, 255, 1)"
                      : "rgba(183, 210, 255, 1)"
                  }`,
                  boxShadow: "none",
                  borderRadius: "9px",
                }}
                expandIcon={<img src={"https://public.ticca.com/images/svg/ContactUsArrow.svg"} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                 {htmlStringFAQ[4] && <>{parser(htmlStringFAQ[4])}</>}
              </AccordionSummary>
              <AccordionDetails
                style={{
                  background: "rgba(183, 210, 255, 1)",
                  boxShadow: "none",
                  borderTop:"1px solid rgba(130, 177, 255, 1)",
                }}
              >
                  {htmlStringFAQ[5] && <>{parser(htmlStringFAQ[5])}</>}
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChangeAccodion("panel4")}
              style={{
                boxShadow: "none",
                backgroundColor: "rgba(183, 210, 255, 1)",
              }}
            >
              <AccordionSummary
                style={{
                  backgroundColor: `${
                    expanded === "panel4"
                      ? " rgba(183, 210, 255, 1)"
                      : "rgba(183, 210, 255, 1)"
                  }`,
                  boxShadow: "none",
                  borderRadius: "9px",
                }}
                expandIcon={<img src={"https://public.ticca.com/images/svg/ContactUsArrow.svg"} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                  {htmlStringFAQ[6] && <>{parser(htmlStringFAQ[6])}</>}
              </AccordionSummary>
              <AccordionDetails
                style={{
                  background: "rgba(183, 210, 255, 1)",
                  boxShadow: "none",
                  borderTop:"1px solid rgba(130, 177, 255, 1)",
                }}
              >
                 {htmlStringFAQ[7] && <>{parser(htmlStringFAQ[7])}</>}
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel5"}
              onChange={handleChangeAccodion("panel5")}
              style={{
                boxShadow: "none",
                backgroundColor: "rgba(183, 210, 255, 1)",
              }}
            >
              <AccordionSummary
                style={{
                  backgroundColor: `${
                    expanded === "panel5"
                      ? " rgba(183, 210, 255, 1)"
                      : "rgba(183, 210, 255, 1)"
                  }`,
                  boxShadow: "none",
                  borderRadius: "9px",
                }}
                expandIcon={<img src={"https://public.ticca.com/images/svg/ContactUsArrow.svg"} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                {htmlStringFAQ[8] && <>{parser(htmlStringFAQ[8])}</>}
              </AccordionSummary>
              <AccordionDetails
                style={{
                  background: "rgba(183, 210, 255, 1)",
                  boxShadow: "none",
                  borderTop:"1px solid rgba(130, 177, 255, 1)",
                }}
              >
               {htmlStringFAQ[9] && <>{parser(htmlStringFAQ[9])}</>}
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel6"}
              onChange={handleChangeAccodion("panel6")}
              style={{
                boxShadow: "none",
                backgroundColor: "rgba(183, 210, 255, 1)",
              }}
            >
              <AccordionSummary
                style={{
                  backgroundColor: `${
                    expanded === "panel6"
                      ? " rgba(183, 210, 255, 1)"
                      : "rgba(183, 210, 255, 1)"
                  }`,
                  boxShadow: "none",
                  borderRadius: "9px",
                }}
                expandIcon={<img src={"https://public.ticca.com/images/svg/ContactUsArrow.svg"} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                {htmlStringFAQ[10] && <>{parser(htmlStringFAQ[10])}</>}
              </AccordionSummary>
              <AccordionDetails
                style={{
                  background: "rgba(183, 210, 255, 1)",
                  boxShadow: "none",
                  borderTop:"1px solid rgba(130, 177, 255, 1)",
                }}
              >
                  {htmlStringFAQ[11] && <>{parser(htmlStringFAQ[11])}</>}
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel7"}
              onChange={handleChangeAccodion("panel7")}
              style={{
                boxShadow: "none",
                backgroundColor: "rgba(183, 210, 255, 1)",
              }}
            >
              <AccordionSummary
                style={{
                  backgroundColor: `${
                    expanded === "panel7"
                      ? " rgba(183, 210, 255, 1)"
                      : "rgba(183, 210, 255, 1)"
                  }`,
                  boxShadow: "none",
                  borderRadius: "9px",
                }}
                expandIcon={<img src={"https://public.ticca.com/images/svg/ContactUsArrow.svg"} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                {htmlStringFAQ[12] && <>{parser(htmlStringFAQ[12])}</>}
              </AccordionSummary>
              <AccordionDetails
                style={{
                  background: "rgba(183, 210, 255, 1)",
                  boxShadow: "none",
                  borderTop:"1px solid rgba(130, 177, 255, 1)",
                }}
              >
                  {htmlStringFAQ[13] && <>{parser(htmlStringFAQ[13])}</>}
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel8"}
              onChange={handleChangeAccodion("panel8")}
              style={{
                boxShadow: "none",
                backgroundColor: "rgba(183, 210, 255, 1)",
              }}
            >
              <AccordionSummary
                style={{
                  backgroundColor: `${
                    expanded === "panel8"
                      ? " rgba(183, 210, 255, 1)"
                      : "rgba(183, 210, 255, 1)"
                  }`,
                  boxShadow: "none",
                  borderRadius: "9px",
                }}
                expandIcon={<img src={"https://public.ticca.com/images/svg/ContactUsArrow.svg"} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                {htmlStringFAQ[14] && <>{parser(htmlStringFAQ[14])}</>}
              </AccordionSummary>
              <AccordionDetails
                style={{
                  background: "rgba(183, 210, 255, 1)",
                  boxShadow: "none",
                  borderTop:"1px solid rgba(130, 177, 255, 1)",
                }}
              >
                  {htmlStringFAQ[15] && <>{parser(htmlStringFAQ[15])}</>}
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel9"}
              onChange={handleChangeAccodion("panel9")}
              style={{
                boxShadow: "none",
                backgroundColor: "rgba(183, 210, 255, 1)",
              }}
            >
              <AccordionSummary
                style={{
                  backgroundColor: `${
                    expanded === "panel8"
                      ? " rgba(183, 210, 255, 1)"
                      : "rgba(183, 210, 255, 1)"
                  }`,
                  boxShadow: "none",
                  borderRadius: "9px",
                }}
                expandIcon={<img src={"https://public.ticca.com/images/svg/ContactUsArrow.svg"} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                {htmlStringFAQ[16] && <>{parser(htmlStringFAQ[16])}</>}
              </AccordionSummary>
              <AccordionDetails
                style={{
                  background: "rgba(183, 210, 255, 1)",
                  boxShadow: "none",
                  borderTop:"1px solid rgba(130, 177, 255, 1)",
                }}
              >
                  {htmlStringFAQ[17] && <>{parser(htmlStringFAQ[17])}</>}
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel10"}
              onChange={handleChangeAccodion("panel10")}
              style={{
                boxShadow: "none",
                backgroundColor: "rgba(183, 210, 255, 1)",
              }}
            >
              <AccordionSummary
                style={{
                  backgroundColor: `${
                    expanded === "panel10"
                      ? " rgba(183, 210, 255, 1)"
                      : "rgba(183, 210, 255, 1)"
                  }`,
                  boxShadow: "none",
                  borderRadius: "9px",
                }}
                expandIcon={<img src={"https://public.ticca.com/images/svg/ContactUsArrow.svg"} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                {htmlStringFAQ[18] && <>{parser(htmlStringFAQ[18])}</>}
              </AccordionSummary>
              <AccordionDetails
                style={{
                  background: "rgba(183, 210, 255, 1)",
                  boxShadow: "none",
                  borderTop:"1px solid rgba(130, 177, 255, 1)",
                }}
              >
                  {htmlStringFAQ[19] && <>{parser(htmlStringFAQ[19])}</>}
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel11"}
              onChange={handleChangeAccodion("panel11")}
              style={{
                boxShadow: "none",
                backgroundColor: "rgba(183, 210, 255, 1)",
              }}
            >
              <AccordionSummary
                style={{
                  backgroundColor: `${
                    expanded === "panel11"
                      ? " rgba(183, 210, 255, 1)"
                      : "rgba(183, 210, 255, 1)"
                  }`,
                  boxShadow: "none",
                  borderRadius: "9px",
                }}
                expandIcon={<img src={"https://public.ticca.com/images/svg/ContactUsArrow.svg"} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                {htmlStringFAQ[20] && <>{parser(htmlStringFAQ[20])}</>}
              </AccordionSummary>
              <AccordionDetails
                style={{
                  background: "rgba(183, 210, 255, 1)",
                  boxShadow: "none",
                  borderTop:"1px solid rgba(130, 177, 255, 1)",
                }}
              >
                  {htmlStringFAQ[21] && <>{parser(htmlStringFAQ[21])}</>}
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel12"}
              onChange={handleChangeAccodion("panel12")}
              style={{
                boxShadow: "none",
                backgroundColor: "rgba(183, 210, 255, 1)",
              }}
            >
              <AccordionSummary
                style={{
                  backgroundColor: `${
                    expanded === "panel12"
                      ? " rgba(183, 210, 255, 1)"
                      : "rgba(183, 210, 255, 1)"
                  }`,
                  boxShadow: "none",
                  borderRadius: "9px",
                }}
                expandIcon={<img src={"https://public.ticca.com/images/svg/ContactUsArrow.svg"} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                {htmlStringFAQ[22] && <>{parser(htmlStringFAQ[22])}</>}
              </AccordionSummary>
              <AccordionDetails
                style={{
                  background: "rgba(183, 210, 255, 1)",
                  boxShadow: "none",
                  borderTop:"1px solid rgba(130, 177, 255, 1)",
                }}
              >
                  {htmlStringFAQ[23] && <>{parser(htmlStringFAQ[23])}</>}
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel13"}
              onChange={handleChangeAccodion("panel13")}
              style={{
                boxShadow: "none",
                backgroundColor: "rgba(183, 210, 255, 1)",
              }}
            >
              <AccordionSummary
                style={{
                  backgroundColor: `${
                    expanded === "panel13"
                      ? " rgba(183, 210, 255, 1)"
                      : "rgba(183, 210, 255, 1)"
                  }`,
                  boxShadow: "none",
                  borderRadius: "9px",
                }}
                expandIcon={<img src={"https://public.ticca.com/images/svg/ContactUsArrow.svg"} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                {htmlStringFAQ[24] && <>{parser(htmlStringFAQ[24])}</>}
              </AccordionSummary>
              <AccordionDetails
                style={{
                  background: "rgba(183, 210, 255, 1)",
                  boxShadow: "none",
                  borderTop:"1px solid rgba(130, 177, 255, 1)",
                }}
              >
                  {htmlStringFAQ[25] && <>{parser(htmlStringFAQ[25])}</>}
              </AccordionDetails>
            </Accordion>
           
            <Accordion
              expanded={expanded === "panel14"}
              onChange={handleChangeAccodion("panel14")}
              style={{
                boxShadow: "none",
                backgroundColor: "rgba(183, 210, 255, 1)",
              }}
            >
              <AccordionSummary
                style={{
                  backgroundColor: `${
                    expanded === "panel14"
                      ? " rgba(183, 210, 255, 1)"
                      : "rgba(183, 210, 255, 1)"
                  }`,
                  boxShadow: "none",
                  borderRadius: "9px",
                }}
                expandIcon={<img src={"https://public.ticca.com/images/svg/ContactUsArrow.svg"} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                {htmlStringFAQ[26] && <>{parser(htmlStringFAQ[26])}</>}
              </AccordionSummary>
              <AccordionDetails
                style={{
                  background: "rgba(183, 210, 255, 1)",
                  boxShadow: "none",
                  borderTop:"1px solid rgba(130, 177, 255, 1)",
                }}
              >
                  {htmlStringFAQ[27] && <>{parser(htmlStringFAQ[27])}</>}
              </AccordionDetails>
            </Accordion>
           
            <Accordion
              expanded={expanded === "panel15"}
              onChange={handleChangeAccodion("panel15")}
              style={{
                boxShadow: "none",
                backgroundColor: "rgba(183, 210, 255, 1)",
              }}
            >
              <AccordionSummary
                style={{
                  backgroundColor: `${
                    expanded === "panel15"
                      ? " rgba(183, 210, 255, 1)"
                      : "rgba(183, 210, 255, 1)"
                  }`,
                  boxShadow: "none",
                  borderRadius: "9px",
                }}
                expandIcon={<img src={"https://public.ticca.com/images/svg/ContactUsArrow.svg"} />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                {htmlStringFAQ[28] && <>{parser(htmlStringFAQ[28])}</>}
              </AccordionSummary>
              <AccordionDetails
                style={{
                  background: "rgba(183, 210, 255, 1)",
                  boxShadow: "none",
                  borderTop:"1px solid rgba(130, 177, 255, 1)",
                }}
              >
                  {htmlStringFAQ[29] && <>{parser(htmlStringFAQ[29])}</>}
              </AccordionDetails>
            </Accordion>
          </div>
        </div>}</Element>
      </div>
    </>
  );
};
export default InvestorCenter;
