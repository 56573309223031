import { useContext, useEffect } from "react";
import NavBar from "./components/Navbar/Navbar";
import {
  fetIpAddress,
  GetcmsContent,
  LoginTokenVerifty,
  PPM,
  BusinessSummaryAccessRequest
} from "./utils/Api/api";
import { useLocation } from "react-router-dom";
import useGeoLocation from "./components/geoLocation/GeoLoaction";
import { Context } from "./contextApi/contextProvider";

function PageRoute({ children }: any) {
  // const { setUserLogin, setUserShowPPM } = useContext(Context);
  // const location = useGeoLocation();
  // const fetchData = async (data: PPM) => {
  //   try {
  //     const response1 = await GetcmsContent(data);
  //     if (response1.data?.status == 200) {
  //       setUserShowPPM(true);
  //     } else {
  //       setUserShowPPM(false);
  //     }
  //   } catch (error) {
  //     setUserShowPPM(false);
  //   }
  // };
  // const fetchIpAddress = async () => {
  //   try {
  //     const { data } = await fetIpAddress();
  //     return data;
  //   } catch (error) {
  //     return "null";
  //   }
  // };

  // const verftyLgoinUser = async () => {
  //   const token = JSON.parse(localStorage.getItem("access_token") as any);
  //   if (token) {
  //     try {
  //       const { data } = await LoginTokenVerifty({ access_token: token });
  //       if (data?.status == 200) {
  //         setUserLogin(true);

  //         fetchData({
  //           post_id: "4725",
  //           ip_address: await fetchIpAddress(),
  //           form_lat: await location?.coordinates.lat,
  //           form_lon: await location?.coordinates.lng,
  //         });
  //       }
  //       if (data?.status !== 200) {
  //         setUserLogin(false);
  //       }
  //     } catch (error) {
  //       // Notfy(error as string);
  //       setUserLogin(false);
  //     }
  //   }
  // };


  // useEffect(() => {
  //   verftyLgoinUser();
  //   // requestPPM()
  // }, []);
  //  localStorage.setItem("access_token",JSON.stringify("eyJhbGciOiJIUzI1NiIsImtpZCI6Im90Yy1rZXkifQ.eyJhdWQiOlsiZGV2Lnd3dy50aWNjYS5sdGQiXSwiZGlkIjoiNDY1MjdjMzBmMzZhMzI2ZTM3Mjg4NjA1ZWNjYTkxMzQiLCJleHAiOjE3MTg2MDQ2NzIsImlhdCI6MTcxNzM5NTA3MiwiaXNzIjoiZGV2LmF1dGgudGljY2EuY29tIiwianRpIjoiK2tleDY3S0FyanQ4Zlh5OTRyK3R1VmhtSmluSHNYbk5NblJmYUg4ZGM0VT0iLCJzdWIiOiJFUmNFSWtLZzFVTE16NzAxN202SDAyME91cXB5T1NENlB1QXdscWVtblBCMlBub2ZrMGhEMFlIOCJ9.5LlzNX0gxDeO64pFUuk5X3Q6BT7tjOznV7hZOgBCg04"))
  return (
    <>
      <div>
        <NavBar />
        {children}
      </div>
    </>
  );
}

export default PageRoute;
