import React, { useEffect, useState, useRef } from "react";
import parser from "html-react-parser";
import { GetPubliccmsContent, fetIpAddress } from "../../utils/Api/api";
import "../home/Home.scss";
import "./when.scss";
import useGeoLocation from "../../components/geoLocation/GeoLoaction";
import { Button } from "@mui/material";
interface Section {
  id: number;
  titleLeft: string;
  titleRight: string;
  descriptionLeft: string;
  descriptionRight: string;
}
const When = () => {
  const loaction = useGeoLocation();
  const [htmlString, setHtmlStr] = useState([]);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const timelineRef = useRef<HTMLDivElement>(null);

  const sections: Section[] = [
    {
      id: 1,
      titleLeft: "Our Progress",
      descriptionLeft: "We have made significant strides...",
      titleRight: "Ongoing",
      descriptionRight: "Development is ongoing...",
    },
    {
      id: 2,
      titleLeft: "Current Status",
      descriptionLeft: "TICCA is continuously developing...",
      titleRight: "Completed",
      descriptionRight: "The project has been completed...",
    },
    {
      id: 3,
      titleLeft: "Looking Ahead",
      descriptionLeft: "We remain excited about...",
      titleRight: "Future",
      descriptionRight: "Plans for the future...",
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      if (!timelineRef.current) return;

      const scrollPosition = window.scrollY + window.innerHeight / 2;
      const sectionElements =
        document.querySelectorAll<HTMLElement>(".timeline-section");

      sectionElements.forEach((section, index) => {
        const rect = section.getBoundingClientRect();
        const sectionTop = rect.top + window.scrollY;
        if (scrollPosition >= sectionTop) {
          setActiveIndex(index);
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const fetchIpAddress = async () => {
    try {
      const { data } = await fetIpAddress();
      return data;
    } catch (error) {
      return "null";
    }
  };
  const cmsContent = async () => {
    try {
      const response1 = await GetPubliccmsContent({
        ip_address: await fetchIpAddress(),
        form_lat: await loaction.coordinates.lat,
        form_lon: await loaction.coordinates.lng,
        post_id: "6811",
      });

      setHtmlStr(response1.data?.content?.split("<!--nextpage-->"));
    } catch (error) {}
  };
  useEffect(() => {
    document.title = "TICCA | WHEN we launch";
    cmsContent();
  }, []);

  return (
    <>
      <div className="home-container">
        <div className="home-bg-container when-bg-img">
          <div className="home-bg-container-dev">
            {htmlString[0] && <>{parser(htmlString[0])}</>}
          </div>
        </div>
      </div>
      <div className="timeline-container" ref={timelineRef}>
        <div className="timeline-line">
          <div
            className="timeline-line-active"
            style={{ height: `${(activeIndex + 1) * 250}px` }}
          ></div>
        </div>
        {/* {sections.map((section, index) => (
          <div
            key={section.id}
            className={`timeline-section ${index % 2 === 0 ? "left" : "right"}`}
          >
            <div className="timeline-content left">
              <h2>{section.titleLeft}</h2>
              <p>{section.descriptionLeft}</p>
            </div>
            <div className="timeline-marker">
              <div
                className={`marker-dot ${
                  index === activeIndex ? "active" : ""
                }`}
              ></div>
            </div>
            <div className="timeline-content right">
              <h2>{section.titleRight}</h2>
              <p>{section.descriptionRight}</p>
            </div>
          </div>
        ))} */}
        <div className={`timeline-section left`}>
          <div className="timeline-content left">
            <img src="https://public.ticca.com/images/svg/WhenCurrentStatus.svg" />
          </div>
          <div className="timeline-marker">
            <div className={`marker-dot`}></div>
          </div>
          <div className="timeline-content card right">
            {htmlString[1] && <>{parser(htmlString[1])}</>}
          </div>
        </div>
        <div className={`timeline-section right`}>
          <div className="timeline-content card right-a">
            {htmlString[2] && <>{parser(htmlString[2])}</>}
          </div>
          <div className="timeline-marker">
            <div className={`marker-dot`}></div>
          </div>

          <div className="timeline-content right">
            <img src="https://public.ticca.com/images/svg/WhenOurProgress.svg" />
          </div>
        </div>
        <div className={`timeline-section left`}>
          <div className="timeline-content left">
            <img src="https://public.ticca.com/images/svg/WhenLookingAhead.svg" />
          </div>
          <div className="timeline-marker">
            <div className={`marker-dot`}></div>
          </div>
          <div className="timeline-content card right">
            {htmlString[3] && <>{parser(htmlString[3])}</>}
          </div>
        </div>
      </div>
      <div className="when-Join-Us-main-dev">
        <div className="when-join-us-dev">
          <Button
            onClick={() => {
              let Api_URL: any = "";
              const env = process.env.REACT_APP_STAGE_ENV;
              switch (env) {
                case "dev":
                  Api_URL = process.env.REACT_APP_TICCA_DEV_SINUP;
                  break;
                case "stage":
                  Api_URL = process.env.REACT_APP_TICCA_STAGE_SINUP;
                  break;
                case "prod":
                  Api_URL = process.env.REACT_APP_TICCA_PRO_SINUP;
                  break;
                default:
                  Api_URL = process.env.REACT_APP_TICCA_DEV_SINUP;
                  break;
              }

              window.location.href = Api_URL;
            }}
            className="when-join-us-title"
            type="button"
          >
            Join Us
          </Button>
          {htmlString[4] && <>{parser(htmlString[4])}</>}
        </div>
      </div>
    </>
  );
};
export default When;
