import React, { useContext, useEffect, useRef, useState } from "react";
import parser from "html-react-parser";
import "./PPMContent.scss";
import {
  AccessBusinessSummary,
  GetcmsContent,
  LoginTokenVerifty,
  PPM,
  fetIpAddress,
  requestPPM
} from "../../utils/Api/api";
import NavBar from "../../components/Navbar/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import { PageRoutes } from "../../utils/pageRoutes";
import useGeoLocation from "../../components/geoLocation/GeoLoaction";
import { Link, Element } from "react-scroll";
import { InView } from "react-intersection-observer";
import { Context } from "../../contextApi/contextProvider";

const ExecutiveSummary = () => {
  const loaction = useGeoLocation();
  const navigator = useNavigate();
  const [htmlString, setHtmlStr] = useState([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const containerRef1 = useRef<HTMLDivElement>(null);
  const rightSlideRef = useRef<HTMLDivElement>(null);
  const [indexSilde, setIndexSilde] = useState(0);
  const [businessMenuToogle, setbusinessMenuToogle] = useState(false);
  const { userLogin, setUserLogin ,setUserShowPPM ,userShowPPM} = useContext(Context);
 const [count ,setCount] = useState(0)
  const resize = () => {
    if (containerRef.current && rightSlideRef.current) {
      const container = containerRef.current;
      const container1 = containerRef1.current;
      const rightSlide = rightSlideRef.current;
      const scale = Math.min(
        window.innerWidth / container.offsetWidth,
        window.innerHeight / container.offsetHeight
      );

      container.style.transform = `scale(${scale})`;
      container.style.transformOrigin = "top left";
      container.style.position = "absolute";
      container.style.top = `calc(${10}px)`;
      container.style.left = `calc(50% - ${
        (container.offsetWidth * scale) / 2
      }px)`;

      const scale1 = 0.18;
      console.log(scale1, "scale1");
      rightSlide.style.transform = `scale(${scale1})})`;
      rightSlide.style.transformOrigin = "top left";
      // rightSlide.style.position = "absolute";
      rightSlide.style.top = `calc(50% - ${
        (rightSlide.offsetHeight * scale1) / 2
      }px)`;
      rightSlide.style.left = `calc(50% - 85px)`;
    }
  };
  const fetchDatas = async (data: requestPPM) => {
    try {
      const response1 = await AccessBusinessSummary(data);
      if (response1.data?.status == 1) {
        setUserShowPPM(true);
      } else {
        setUserShowPPM(false);
        navigator("/")
      }
    } catch (error) {
      setUserShowPPM(false);
    }
  };

  useEffect(() => {
    if (!userLogin) {
      const verftyLgoinUser = async () => {
        const token = JSON.parse(localStorage.getItem("access_token") as any);
        if (token) {
          try {
            const { data } = await LoginTokenVerifty({ access_token: token });
            if (data?.status == 200) {
              setUserLogin(true);
            
            }
            if (data?.status !== 200) {
              setUserLogin(false);
              navigator("/");
            }
          } catch (error) {
            // Notfy(error as string);
            setUserLogin(false);
            navigator("/");
          }
        } else {
          navigator("/");
        }
      };
      verftyLgoinUser();
    }

    const fetchData = async (data: PPM) => {
      try {
        const response1 = await GetcmsContent(data);
        setHtmlStr(response1.data?.content?.split("<!--nextpage-->"));
        resize();
      } catch (error) {
        console.error("Error fetching data from endpoint1:", error);
      }
    };

    const fetchIpAddress = async () => {
      try {
        const { data } = await fetIpAddress();
           fetchDatas({
            ip_address: data,
            latitude: await loaction.coordinates.lat,
            longitude: await loaction.coordinates.lng,
          })
        
        fetchData({
          post_id: "4987",
          ip_address: data,
          form_lat: await loaction.coordinates.lat,
          form_lon: await loaction.coordinates.lng,
        });
      } catch (error) {}
    };
    fetchIpAddress();
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, []);

  return (
    <>

      <div
        className={`bussiness-side-menu ${
          businessMenuToogle ? "left-side-animation" : "right-side-animation"
        }`}
      >
        <div
          onClick={() => {setbusinessMenuToogle(!businessMenuToogle)
           if(count==0){
            setIndexSilde(0)
           }
           else{
            setCount(count+1)
           }
          }}
          className="bussiness-side-div"
        >
          <div className="bussiness-left-arrow">
            <div className="bussiness-dev-list">
              <svg
                width="10"
                height="18"
                viewBox="0 0 10 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.28033 0.71967C9.57322 1.01256 9.57322 1.48744 9.28033 1.78033L1.81066 9.25L9.28033 16.7197C9.57322 17.0126 9.57322 17.4874 9.28033 17.7803C8.98744 18.0732 8.51256 18.0732 8.21967 17.7803L0.21967 9.78033C-0.0732231 9.48744 -0.0732231 9.01256 0.21967 8.71967L8.21967 0.71967C8.51256 0.426777 8.98744 0.426777 9.28033 0.71967Z"
                  fill="#074EDC"
                />
              </svg>
            </div>
            <div className="">
              <svg
                width="38"
                height="35"
                viewBox="0 0 38 35"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_3929_16722)">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6 10.1562C6 9.51767 6.51767 9 7.15625 9H31.8229C32.4615 9 32.9792 9.51767 32.9792 10.1562C32.9792 10.7948 32.4615 11.3125 31.8229 11.3125H7.15625C6.51767 11.3125 6 10.7948 6 10.1562ZM6 17.8646C6 17.226 6.51767 16.7083 7.15625 16.7083H31.8229C32.4615 16.7083 32.9792 17.226 32.9792 17.8646C32.9792 18.5032 32.4615 19.0208 31.8229 19.0208H7.15625C6.51767 19.0208 6 18.5032 6 17.8646ZM6 25.5729C6 24.9343 6.51767 24.4167 7.15625 24.4167H31.8229C32.4615 24.4167 32.9792 24.9343 32.9792 25.5729C32.9792 26.2115 32.4615 26.7292 31.8229 26.7292H7.15625C6.51767 26.7292 6 26.2115 6 25.5729Z"
                    fill="#074EDC"
                  />
                  <rect
                    x="1"
                    y="1"
                    width="36"
                    height="33"
                    rx="3"
                    stroke="#074EDC"
                    stroke-width="2"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_3929_16722">
                    <rect width="38" height="35" rx="4" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
        </div>
        <div className="bussiness-side-tatile-dev">
          <p>List of Content</p>
          <div
            style={{ position: "absolute", right: "10px", top: "10px" }}
            onClick={() => setbusinessMenuToogle(false)}
          >
            <samp>
              <svg
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Group 5323">
                  <g id="Group 1258">
                    <g id="Group 1242">
                      <path
                        id="Line 11"
                        d="M1 1.48621L14.8182 15.3564"
                        stroke="white"
                        strokeLinecap="square"
                      />
                      <path
                        id="Line 12"
                        d="M15.2498 1.05273L1.43164 14.9229"
                        stroke="white"
                        strokeLinecap="square"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </samp>
          </div>
        </div>
        <div
          className="scroll-dev"
          style={{
            position: "relative",
            overflowX: "hidden",
            marginTop: "-10px",
            zIndex: "-1",
          }}
        >
          <div
            ref={rightSlideRef}
            className="ex-su-maindiv-sub"
            style={{ scale: "0.18", marginLeft: "40px" }}
          >
            {htmlString &&
              htmlString.map((elem: string, index: any) => (
                <>
                  <Link to={`bs-${index}`} smooth={true} duration={500}>
                    <div
                      style={{
                        marginBottom: "100px",
                        cursor: "pointer",
                        borderRadius: "20PX",
                        border: `${
                          index == indexSilde
                            ? "15px solid rgba(71, 144, 255, 1)"
                            : "15px solid transparent"
                        }`,
                      }}
                      key={index}
                      className="ex-su-maindiv"
                      onClick={() => setIndexSilde(index)}
                    >
                      <div
                        className={`index-dev ${
                          index === indexSilde && "active-index"
                        } `}
                      >
                        {index + 1}
                      </div>
                      {parser(elem)}
                      <div
                        style={{
                          justifyContent: `${
                            index === 0 ? "end" : "space-between"
                          }`,
                        }}
                        className="footer-div"
                      >
                        {index !== 0 && (
                          <img
                            src="https://public.ticca.com/images/svg/TiccaLogo_Null_EmailVerification_WhiteRedOrangeGreen_150X33.svg"
                            alt="logo"
                          />
                        )}
                        <p>Commercial in confidence</p>
                      </div>
                    </div>
                  </Link>
                </>
              ))}
          </div>
        </div>
      </div>

      <div
        style={{ marginTop: "50px" }}
        ref={containerRef}
        className="ex-su-maindiv-sub"
      >
        {htmlString &&
          htmlString.map((elem: string, index: any) => (
            <div key={index} className="ex-su-maindiv">
              <InView
                threshold={1}
                rootMargin="-0%"
                as="div"
                onChange={() => setIndexSilde(index)}
              >
                <Element name={`bs-${index}`} className={`bs-${index}`}>
                  {parser(elem)}
                </Element>
                <div
                  style={{
                    justifyContent: `${index === 0 ? "end" : "space-between"}`,
                  }}
                  className="footer-div"
                >
                  {index !== 0 && (
                    <img
                      src="https://public.ticca.com/images/svg/TiccaLogo_Null_EmailVerification_WhiteRedOrangeGreen_150X33.svg"
                      alt="logo"
                    />
                  )}
                  <p>Commercial in confidence</p>
                </div>
              </InView>
            </div>
          ))}
      </div>
    </>
  );
};

export default ExecutiveSummary;
